import React, { useEffect, useState } from "react";
import "./receipt.css";
import { useTranslation } from "react-i18next";
import { useAppState } from "../../contexts/formContext";

function Receipt({ step, price }) {
  const [t, i18n] = useTranslation("global");
  const [localPrice, setLocalPrice] = useState();
  const [state, setState] = useAppState();

  useEffect(() => {
    if (price) {
      if (price < 80) {
        setState((state) => {
          return { ...state, price: 80 };
        });
      } else {
        const basePrice = 80;
        const supplement = price - basePrice;
        setLocalPrice({ basePrice, supplement });
      }
    }
  }, [state]);

  return (
    <div className="receipt-wrapper">
      <h4 className="receipt-title">
        {step === 6 ? t("price") : t("trefundstep4.fields.rectext")}
      </h4>
      <div className="price-description">
        <div
          className="price-description-item"
          style={{ marginBottom: "12px" }}
        >
          <div className="no-checkbox-block marginZero">
            <div className="no-checkbox no-pointer">
              <div className="no-checkbox-mark">
                <img
                  src="/checkMarkIcon.png"
                  alt="checkmark"
                  className="check-mark-icon"
                />
              </div>
            </div>
            <p className="no">{t("payment.baseprice")}</p>
            <div className="info-icon">
              <img src="/infoIcon.png" alt="compensations info" />
              <p className="show-info" id="base-desc">
                {t("payment.baseDescription")}
              </p>
            </div>
          </div>
          <div className="sub-price-item">
            {price && price === 80 ? 80 : localPrice?.basePrice}.-
          </div>
        </div>
        <div className="price-description-item">
          <div className="no-checkbox-block marginZero">
            <div className="no-checkbox no-pointer">
              <div className="no-checkbox-mark">
                <img
                  src="/checkMarkIcon.png"
                  alt="checkmark"
                  className="check-mark-icon"
                />
              </div>
            </div>
            <p className="no">{t("payment.supplement")}</p>
            <div className="info-icon">
              <img src="/infoIcon.png" alt="compensations info" />
              <p className="show-info" id="sup-desc">
                {t("payment.supplementDescription")}
              </p>
            </div>
          </div>
          <div className="sub-price-item">{localPrice?.supplement}.-</div>
        </div>
      </div>
      <div className="divider"></div>
      <div className="total-wrapper">
        <p>Total : {price}.- CHF</p>
      </div>
    </div>
  );
}

export default Receipt;
