import React, { useEffect, useState } from "react";
import CustomSelect from "../Customselect";
import "./dependentchild.css";
import { useAppState } from "../../contexts/formContext";
import { useTranslation } from "react-i18next";

function DependentChild({
  yearOptions,
  childStatusOptions,
  child,
  emptyChild,
  invalidateStep1Errors,
}) {
  const [showYearOptions, setShowYearOptions] = useState(false);
  const [showChildStatusOptions, setShowChildStatusOptions] = useState(false);
  const [t, i18n] = useTranslation("global");

  const [state, setState] = useAppState();

  useEffect(() => {
    let res = false;
    state?.["Enfants a scharge"].forEach((element) => {
      if (
        element["Annee de naissance"] &&
        element?.statut?.frenchValue?.length > 0
      ) {
        res = true;
      } else {
        res = false;
      }
    });
    if (res === true) {
      invalidateStep1Errors("Enfants a scharge data");
    }
  }, [state]);

  const handleChangeYearBirth = (child) => {
    return function (item) {
      setState((state) => {
        const result = state?.["Enfants a scharge"].map((el) => {
          if (el.id === child.id) {
            return { ...el, "Annee de naissance": item.value };
          } else {
            return el;
          }
        });

        return { ...state, "Enfants a scharge": result };
      });
      setShowYearOptions(false);
    };
  };

  const handleChangeChildStatus = (child) => {
    return function (item) {
      setState((state) => {
        const result = state?.["Enfants a scharge"].map((el) => {
          if (el.id === child.id) {
            return { ...el, statut: item };
          } else {
            return el;
          }
        });

        return { ...state, "Enfants a scharge": result };
      });
      setShowChildStatusOptions(false);
    };
  };

  const handleDeleteDependentCild = (id) => {
    setState((state) => {
      const filteredChildren = state?.["Enfants a scharge"].filter((child) => {
        return child.id !== id;
      });

      if (filteredChildren.length === 0) {
        const { ["Enfants a scharge"]: child, ...rest } = state;

        return { ...rest };
      } else {
        return { ...state, "Enfants a scharge": filteredChildren };
      }
    });
  };
  return (
    <div className="dependent-children-inputs">
      <CustomSelect
        label={t("taxrefundstep1.fields.year")}
        selectedValueWidth="45%"
        options={yearOptions}
        showOptions={showYearOptions}
        setShowOptions={setShowYearOptions}
        onChange={handleChangeYearBirth(child)}
        value={child?.["Annee de naissance"]}
        handleResetValue={() => {}}
        optionsHeight="200px"
        overflowOptions="scroll"
      />
      <CustomSelect
        label={t("taxrefundstep1.fields.status")}
        selectedValueWidth="45%"
        options={childStatusOptions}
        showOptions={showChildStatusOptions}
        setShowOptions={setShowChildStatusOptions}
        onChange={handleChangeChildStatus(child)}
        value={
          i18n.language === "en"
            ? child?.statut?.value
            : child?.statut?.frenchValue
        }
        handleResetValue={() => {}}
      />
      <button
        className="delete-dependent-child-button"
        onClick={() => handleDeleteDependentCild(child.id)}
      >
        <img src="/deleteCitcleButton.png" alt="delete dependent child" />
      </button>
      {emptyChild && (
        <p
          style={{
            color: "red",
            fontSize: "12px",
            position: "absolute",
            left: "0",
            bottom: "-14px",
          }}
        >
          Fill all fields
        </p>
      )}
    </div>
  );
}

export default DependentChild;
