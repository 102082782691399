import React, { useEffect } from "react";
import "./success.css";

import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useAppState } from "../../contexts/formContext";

function Success(props) {
  const [t, i18n] = useTranslation("global");
  const [state, setState] = useAppState();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const searchParams = new URLSearchParams(location.search);
    const paramValue = searchParams.get('status');
    console.log(state);
    if (location?.state?.pathname === "/tax-refund") {
      setState((state) => {
        return { step: 1 };
      });
      navigate(location.pathname, { state: null });
    } 
    else if (paramValue === 'success'){
      setState((state) => {
        return { step: 1 };
      });
      navigate(location.pathname, { state: null });
    }
    else {
      navigate("/");
    }
  }, []);

  return (
    <div className="refund-desc">
      <div className="form-container">
        <h4>{t("success.title")}</h4>
        <p className="form-description">
          {i18n.language === "en" ? (
            <p className="form-description" style={{ marginBottom: "15px" }}>
              Your information has been&nbsp;
              <span className="hightlight-text">successfully</span>
              &nbsp; transmitted. Our team of professionals will take care of
              processing your declaration and submitting it to the&nbsp;
              <span className="hightlight-text">tax authorities</span>.
            </p>
          ) : (
            <p className="form-description" style={{ marginBottom: "15px" }}>
              Vos informations ont été transmises{" "}
              <span className="hightlight-text">avec succès</span>. Notre équipe
              de&nbsp;
              <span className="hightlight-text">professionnels</span> se
              chargera d'exécuter votre{" "}
              <span className="hightlight-text">déclaration</span> et de la
              transmettre à{" "}
              <span className="hightlight-text">l'administration fiscale</span>.
            </p>
          )}
          <p className="form-description" style={{ marginBottom: "15px" }}>
            {t("success.text.within")}&nbsp;
            <span className="hightlight-text">{t("success.text.72")}</span>
            {t("success.text.youwill")}
          </p>
          <p style={{ marginBottom: "15px" }}>
            <ul style={{ listStyle: "initial", paddingLeft: "20px" }}>
              <li className="hightlight-text">{t("success.text.confir")}</li>
              <li className="hightlight-text">{t("success.text.copy")}</li>
              <li className="hightlight-text">{t("success.text.estim")}</li>
              <li className="hightlight-text">{t("success.text.copyof")}</li>
            </ul>
          </p>
          <p className="form-description">
            {t("success.text.thank")}{" "}
            <span className="hightlight-text">VEG</span>
            <span className="hightlight-text-blue">TAX.</span> !
          </p>
        </p>
      </div>

      <div
        className="who-we-are-block"
        style={{ marginTop: "400px", position: "relative" }}
      >
        <div className="success-avatar">
          <img src="/avatarThumCropped - Copy.png" alt="tax refund" />
        </div>
        <div className="page-hero">
          <div className="homepage-hero-slogan-block">
            <h1 className="homepage-hero-slogan">
              <span className="who-we-are-title">
                {t("homepage.whoweare.title")}
              </span>
            </h1>
            {i18n.language === "en" ? (
              <p className="homepage-hero-slogan-text">
                <span className="hightlight-text">VEG Consulting</span>
                {t("homepage.whoweare.recognized")}
                <span className="hightlight-text">
                  {t("homepage.whoweare.tax")}
                </span>{" "}
                {t("and")}
                <span className="hightlight-text">
                  {" "}
                  {t("homepage.whoweare.financial")}{" "}
                </span>
                {t("homepage.whoweare.consulting")}
                <span className="hightlight-text">VEG</span>
                <span className="hightlight-text-blue">TAX.</span>{" "}
                {t("homepage.whoweare.thisinnovative")}&nbsp;
                <span className="hightlight-text">
                  {t("homepage.whoweare.swissind")}{" "}
                </span>
                {t("homepage.whoweare.handle")}.
              </p>
            ) : (
              <p className="homepage-hero-slogan-text">
                <span className="hightlight-text">VEG Consulting</span>, un
                leader reconnu dans le domaine du conseil&nbsp;
                <span className="hightlight-text">fiscal</span> et{" "}
                <span className="hightlight-text">financier</span> dans le
                canton de Vaud, est fier d'annoncer le lancement de son tout{" "}
                <span className="hightlight-text">nouveau produit :</span>{" "}
                <span className="hightlight-text">VEG</span>{" "}
                <span className="hightlight-text-blue">TAX.</span> Ce site
                internet <span className="hightlight-text">innovant</span> est
                spécialement conçu pour{" "}
                <span className="hightlight-text">solutionner</span> la manière
                dont les particuliers suisses gèrent leurs{" "}
                <span className="hightlight-text">déclarations d'impôt</span>.
              </p>
            )}
            <button className="get-tax-refund-btn" style={{ width: "125px" }}>
              {t("homepage.readmore")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Success;
