import React from "react";
import "./alimonyinputs.css";
import { useTranslation } from "react-i18next";
import ValidationError from "../validationError";

function AlimonyInputs({
  onChange,
  incomeAlimony,
  pensAlimentStep2Montant,
  partner2PensAlimentStep2Montant,
}) {
  const [t, i18n] = useTranslation("global");
  return (
    <div className="alimony-inputs-wrapper">
      <h6 className="input-title">{t("trefundstep2.fields.resfrom")}</h6>
      <div className="alimony-input-wrapper ">
        <input
          className="activity-input"
          type="text"
          placeholder={t("trefundstep2.fields.name")}
          onChange={(e) => onChange(e, "Nom/Prenom")}
          value={incomeAlimony?.["Nom/Prenom"] || ""}
        />
      </div>
      <div className="alimony-input-wrapper">
        <input
          className="activity-input"
          type="text"
          placeholder={t("trefundstep2.fields.addr")}
          onChange={(e) => onChange(e, "Adresse")}
          value={incomeAlimony?.["Adresse"] || ""}
        />
      </div>
      <div className="alimony-input-wrapper ">
        <input
          className="activity-input"
          type="text"
          placeholder={t("trefundstep2.fields.res")}
          onChange={(e) => onChange(e, "Domicile")}
          value={incomeAlimony?.["Domicile"] || ""}
        />
      </div>
      <div className="alimony-input-wrapper" style={{ position: "relative" }}>
        <ValidationError
          error={pensAlimentStep2Montant}
          message="Numbers only"
          topPosition={"-15px"}
        />
        <ValidationError
          error={partner2PensAlimentStep2Montant}
          message="Numbers only"
          topPosition={"-15px"}
        />

        <input
          className="activity-input"
          type="text"
          placeholder={t("trefundstep2.fields.yearamount")}
          onChange={(e) => onChange(e, "Montant")}
          value={incomeAlimony?.["Montant"] || ""}
        />
      </div>
    </div>
  );
}

export default AlimonyInputs;
