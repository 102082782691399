import React, { useEffect, useState } from "react";
import "./failure.css";
import { useTranslation } from "react-i18next";
import ValidationError from "../validationError";
import axios from "axios";
import url from "../../constants";
import { useLocation, useNavigate } from "react-router";

function Failure(props) {
  const [state, setState] = useState({});
  const [serverError, setServerError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [emailError, setEmailError] = useState();
  const [sendingRequest, setSendingRequest] = useState(false);
  const [phoneError, setPhoneError] = useState();

  const navigate = useNavigate();

  const [t, i18n] = useTranslation("global");

  const location = useLocation();

  const [personalInformationErrors, setPersonalInformationValidationErrors] =
    useState();

  useEffect(() => {
    window.scrollTo(0, 0);    
    const searchParams = new URLSearchParams(location.search);
    const paramValue = searchParams.get('status');
    if (location?.state?.pathname === "/tax-refund") {
      setState((state) => {
        return { step: 1 };
      });
      navigate(location.pathname, { state: null });
    } else if (paramValue === 'fail'){
      setState((state) => {
        return { step: 1 };
      });
      navigate(location.pathname, { state: null });
    } else {
      navigate("/");
    }
  }, []);

  const invalidatePersonalInformation = () => {
    setPersonalInformationValidationErrors(null);
  };

  useEffect(() => {
    let persRes = false;
    let emRes = false;
    let phoneRes = false;
    const persInformationFields = ["name and lastname", "emai", "mobile"];

    persInformationFields.forEach((info) => {
      if (state?.[info]) {
        persRes = true;
      } else {
        persRes = false;
      }
    });

    if (persRes === true) {
      invalidatePersonalInformation();
    }

    if (isValidEmail(state?.email)) {
      emRes = true;
    }

    if (emRes === true) {
      invalidateEmail();
    }

    if (isValidPhone(state.mobile) === true) {
      phoneRes = true;
    }

    if (phoneRes === true) {
      invalidatePhone();
    }
  }, [state]);

  const handleChangePersonalEnformation = (key, e) => {
    setState((state) => {
      return { ...state, [key]: e.target.value.trimStart() };
    });
  };

  const validatePersonalInformation = (form) => {
    let result = true;
    const requiredFields = ["name and lastname", "email", "mobile"];
    if (!form) {
      result = false;
      setPersonalInformationValidationErrors({
        "name and lastname": "name and lastname",
        email: "email",
        mobile: "mobile",
      });
    } else {
      requiredFields.forEach((key) => {
        if (!form[key]) {
          setPersonalInformationValidationErrors((errors) => {
            return { ...errors, [key]: key };
          });
          result = false;
        }
      });
    }

    return result;
  };

  const handleSubmit = (form) => {
    if (
      !validatePersonalInformation(form) ||
      !validateEmail(form?.email, form?.mobile)
    ) {
      return;
    } else {
      sendRefusalForm();
    }
  };

  const sendRefusalForm = () => {
    try{
      setSendingRequest(true);
      axios
        .post(
          `${url}failure-form`,
          {
            nom: state?.["name and lastname"],
            email: state?.email,
            mobile: state?.mobile,
          },
          { withCredentials: true }
        )
        .then((res) => {
          setServerError(false);
          setSendingRequest(false);
          setSuccess(true);
        })
        .catch((error) => {
          setSuccess(false);
          setServerError(error);
          setSendingRequest(false);
        });
      }catch(error){
        setSuccess(false);
        setServerError(error);
        setSendingRequest(false);
      }
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function isValidPhone(phone) {
    return /^[+]*(?:\d[+]*){6,12}$/.test(phone);
  }

  const validateEmail = (email, mobile) => {
    let result = true;
    if (email && !isValidEmail(email)) {
      setEmailError("Email is invalid");
      result = false;
    }
    if (mobile && !isValidPhone(mobile)) {
      setPhoneError("Phone number Is is invalid");
      result = false;
    }

    return result;
  };

  const invalidateEmail = () => {
    setEmailError(null);
  };

  const invalidatePhone = () => {
    setPhoneError(null);
  };

  return (
    <div className="refund-desc form-container fullwidth">
      <h4 className="form-section-title">{t("failure.title")}</h4>
      <p className="form-description">
        {i18n.language === "en" ? (
          <>
            <p className="form-description" style={{ marginBottom: "20px" }}>
              Our goal is to provide&nbsp;
              <span className="hightlight-text">quality service</span>
              &nbsp; at an&nbsp;
              <span className="hightlight-text">affordable rate</span>.
              Unfortunately, your profile exceeds the threshold established for
              our package.
            </p>
            <p className="form-description" style={{ marginBottom: "20px" }}>
              <span className="hightlight-text">However</span>, we have good
              news! Our parent company,&nbsp;
              <span className="hightlight-text">Veg Consulting</span>, offers
              services more tailored to your needs. They specialize in handling
              more&nbsp;
              <span className="hightlight-text">complex cases</span>
              &nbsp; and can provide&nbsp;
              <span className="hightlight-text">customized solutions</span>
              &nbsp; that better address your requirements. (For more
              information, click{" "}
              <a
                href="https://www.vegconsulting.ch/"
                target="_blank"
                rel="noreferrer"
                style={{
                  color: "#55c9ea",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                here
              </a>
              )
            </p>
          </>
        ) : (
          <>
            <p className="form-description" style={{ marginBottom: "20px" }}>
              Notre objectif est de fournir un service de{" "}
              <span className="hightlight-text">qualité</span> à un{" "}
              <span className="hightlight-text">tarif abordable</span>. Hélas,
              votre profil <span className="hightlight-text">dépasse</span> le
              seuil établi pour notre{" "}
              <span className="hightlight-text">formule</span>.
            </p>
            <p className="form-description" style={{ marginBottom: "20px" }}>
              <span className="hightlight-text">Cependant</span>, nous avons une{" "}
              <span className="hightlight-text"> bonne nouvelle</span> ! Notre
              société mère,{" "}
              <span className="hightlight-text">Veg Consulting</span>, propose
              des services plus adaptés à vos besoins. Ils sont spécialisés dans
              la gestion de cas{" "}
              <span className="hightlight-text">plus élaborés</span> et peuvent
              offrir des{" "}
              <span className="hightlight-text">solutions personnalisées</span>{" "}
              qui répondent mieux à vos{" "}
              <span className="hightlight-text">besoins</span>. (pour plus
              d'informations cliquez{" "}
              <a
                href="https://www.vegconsulting.ch/"
                target="_blank"
                rel="noreferrer"
                style={{
                  color: "#55c9ea",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                ici
              </a>
              )
            </p>
          </>
        )}

        <p style={{ marginBottom: "20px" }}>
          <span className="hightlight-text" style={{ textDecoration: "none" }}>
            {t("failure.doyou")}
          </span>
        </p>
        <div className="failure-avatar">
          <img src="/avatarPointingSign.png" />
        </div>
      </p>
      {!success ? (
        <>
          <div
            className="personal-information-form"
            style={{ position: "relative" }}
          >
            <ValidationError
              error={personalInformationErrors}
              topPosition={"-25px"}
              message={t("reqfields")}
            />
            <ValidationError
              error={serverError}
              message={serverError?.message}
              topPosition={"-45px"}
            />
            <div className="regular-input-wrapper">
              <input
                type="text"
                className="activity-input"
                onChange={(e) =>
                  handleChangePersonalEnformation("name and lastname", e)
                }
                value={state?.["name and lastname"] || ""}
              />
              <div className="select-label">
                {t("trefundstep4.fields.nlname")}
              </div>
            </div>
            <div
              className="regular-input-wrapper"
              style={{ position: "relative" }}
            >
              <ValidationError
                error={emailError}
                message={emailError}
                topPosition={"-25px"}
              />
              <input
                type="text"
                className="activity-input"
                onChange={(e) => handleChangePersonalEnformation("email", e)}
                value={state?.email || ""}
              />
              <div className="select-label">Email</div>
            </div>
            <div
              className="regular-input-wrapper"
              style={{ position: "relative" }}
            >
              <ValidationError
                error={phoneError}
                message={phoneError}
                topPosition={"-25px"}
              />
              <input
                type="text"
                className="activity-input"
                onChange={(e) => handleChangePersonalEnformation("mobile", e)}
                value={state?.mobile || ""}
              />
              <div className="select-label">Mobile</div>
            </div>
          </div>
          <div className="next-step-button-container">
            <button
              className="next-step-button"
              onClick={() => handleSubmit(state)}
            >
              {" "}
              {t("submit")}
            </button>
            <button
              className="back-button"
              style={{ marginLeft: "40px", backgroundColor: "#e8def8" }}
              onClick={() => navigate("/tax-refund")}
            >
              Back to formular
            </button>
          </div>
        </>
      ) : (
        <div className="message-sent">
          <h4>{t("requestsent")}</h4>
        </div>
      )}

      {sendingRequest && <div className="page-overlay"></div>}
    </div>
  );
}

export default Failure;
