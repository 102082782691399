import React, { useEffect } from "react";

import "./ourconcept.css";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

function OurConcept(props) {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page-margin">
      <h4 className="page-title">{t("ourcon")}</h4>
      {i18n.language === "en" && (
        <div className="eligibility-descriptions ">
          <p className="eligibility-description">
            Our <span className="hightlight-text">product</span> is an
            innovative web solution designed to&nbsp;
            <span className="hightlight-text">simplify</span> the online tax
            declaration process. Our goal is to make this process as&nbsp;
            <span className="hightlight-text">efficient</span> and&nbsp;
            <span className="hightlight-text">affordable </span> as possible
            while maintaining a&nbsp;
            <span className="hightlight-text">high quality of service</span>.
            Here are the key aspects of our offering:
          </p>
          <p className="eligibility-description">
            <span className="hightlight-text-blue">
              Accessibility and Simplicity:
            </span>
            &nbsp; Our platform is designed to be easily&nbsp;
            <span className="hightlight-text">accessible</span> and
            user-friendly for a{" "}
            <span className="hightlight-text">wide range</span> of clients. It
            guides the user through the tax declaration process&nbsp;
            <span className="hightlight-text">intuitively</span>, simplifying
            complicated steps.
          </p>
          <p className="eligibility-description">
            <span className="hightlight-text-blue">
              Transparent and Affordable Pricing:
            </span>
            &nbsp; We are committed to providing this service at a maximum rate
            of&nbsp;
            <span className="hightlight-text">CHF 350</span>. This pricing has
            been established to cater to clients with
            <span className="hightlight-text"> standard tax profiles</span>,
            without major complexities. The pricing model aims to ensure that
            our services remain
            <span className="hightlight-text"> accessible </span>
            and <span className="hightlight-text">cost-effective </span>
            for the majority of users.
          </p>
          <p className="eligibility-description">
            <span className="hightlight-text-blue">
              Targeting Standard Profiles:
            </span>
            Our service is ideal for clients with relatively&nbsp;
            <span className="hightlight-text">simple</span> and&nbsp;
            <span className="hightlight-text">
              straightforward tax situations
            </span>
            . This includes individuals with regular incomes, few or no complex
            investments, and standard deductions. By focusing on these profiles,
            we can provide a <span className="hightlight-text">faster</span>
            &nbsp;and&nbsp;
            <span className="hightlight-text">more efficient</span>
            &nbsp;service.
          </p>
          <p className="eligibility-description">
            <span className="hightlight-text-blue">
              Solution for Complex Profiles:
            </span>
            For clients with&nbsp;
            <span className="hightlight-text">more complex needs</span> that go
            beyond our standard pricing framework, we provide guidance to
            our&nbsp;
            <span className="hightlight-text">parent company</span>, specialized
            in more intricate tax situations.
          </p>
          <p className="eligibility-description">
            <span className="hightlight-text-blue">In summary</span>, our web
            product is a <span className="hightlight-text">practical</span>
            ,&nbsp;
            <span className="hightlight-text">affordable</span>, and&nbsp;
            <span className="hightlight-text">quality</span> solution for online
            tax declarations, primarily aimed at clients with&nbsp;
            <span className="hightlight-text">standard tax profiles</span>. Our
            commitment to transparent pricing and service quality sets us apart,
            while also providing&nbsp;
            <span className="hightlight-text">tailored solutions</span> for
            more&nbsp;
            <span className="hightlight-text">complex cases</span>.
          </p>
        </div>
      )}
      {i18n.language === "fr" && (
        <div className="eligibility-descriptions ">
          <p className="eligibility-description">
            Notre <span className="hightlight-text">produit</span> est une
            solution web innovante conçue pour simplifier le processus de
            déclaration d'impôt en ligne. Notre{" "}
            <span className="hightlight-text">objectif</span> est de rendre ce
            processus aussi <span className="hightlight-text">efficace</span> et{" "}
            <span className="hightlight-text">abordable</span> que possible,
            tout en maintenant une haute qualité de service. Voici les{" "}
            <span className="hightlight-text">principaux aspects </span>
            de notre offre.
          </p>
          <p className="eligibility-description">
            <span className="hightlight-text-blue">
              Accessibilité et Simplicité :
            </span>{" "}
            Notre plateforme est conçue pour être
            <span className="hightlight-text"> facilement accessible</span> et
            utilisable par une large gamme de clients. Elle guide l'utilisateur
            à travers le processus de déclaration d'impôt de manière intuitive,
            en <span className="hightlight-text">simplifiant</span> les étapes
            compliquées.
          </p>
          <p className="eligibility-description">
            <span className="hightlight-text-blue">
              Tarification Transparente et Abordable :{" "}
            </span>{" "}
            Nous nous engageons à offrir ce service à un tarif maximal de{" "}
            <span className="hightlight-text">CHF 350</span>. Cette tarification
            a été établie pour répondre aux besoins des clients ayant des
            <span className="hightlight-text"> profils fiscaux standard</span>,
            sans complexités majeures. Ce modèle tarifaire vise à garantir que
            nos services restent accessibles et économiques pour la majorité des
            utilisateurs.
          </p>
          <p className="eligibility-description">
            <span className="hightlight-text-blue">
              Ciblage des Profils Standards :
            </span>{" "}
            Notre service est idéal pour les clients avec des situations
            fiscales relativement{" "}
            <span className="hightlight-text">simples</span> et directes. Cela
            inclut les personnes avec des revenus réguliers, peu ou pas
            d'investissements complexes, et des déductions standard. En nous
            concentrant sur ces profils, nous pouvons offrir un service plus
            rapide et plus <span className="hightlight-text">efficient</span>.
          </p>
          <p className="eligibility-description">
            <span className="hightlight-text-blue">
              Solution pour Profils Complexes :
            </span>{" "}
            Pour les clients ayant des besoins
            <span className="hightlight-text"> plus complexes</span> qui
            dépassent notre cadre de tarification standard, nous offrons des{" "}
            <span className="hightlight-text">orientations</span> vers notre{" "}
            <span className="hightlight-text">société mère</span>, spécialisée
            dans les situations fiscales plus élaborées.
          </p>
          <p className="eligibility-description">
            <span className="hightlight-text-blue">En résumé</span>, notre
            produit web est une solution{" "}
            <span className="hightlight-text">pratique</span>,{" "}
            <span className="hightlight-text">abordable</span>, et de
            <span className="hightlight-text"> qualité </span>pour les
            déclarations d'impôt en ligne, destinée principalement aux clients
            avec des{" "}
            <span className="hightlight-text">profils fiscaux standards</span>.
            Notre engagement envers la transparence des prix et la qualité du
            service nous distingue, tout en offrant des{" "}
            <span className="hightlight-text">solutions adaptées</span> pour les
            cas plus <span className="hightlight-text">complexes</span>.
          </p>
        </div>
      )}

      <div className="ready-to-taxrefund">
        <h3 className="big-title" style={{ marginBottom: "10px" }}>
          {t("how.ready")}
        </h3>
        <p
          className="ready-to-taxrefund-description"
          style={{ marginBottom: "10px" }}
        >
          {t("how.click")}&nbsp;
          <span className="hightlight-text">VEG</span>
          <span className="hightlight-text-blue">TAX.</span> {t("how.guide")}
        </p>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <button
            className="get-tax-refund-btn"
            style={{ width: "125px" }}
            onClick={() => navigate("/tax-refund")}
          >
            {t("how.letsgo")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default OurConcept;
