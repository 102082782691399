import React, { useEffect, useState } from "react";
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useAppState } from "../../contexts/formContext";
import { useNavigate, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import url from "../../constants";

const PUBLIC_KEY =
  "pk_test_51OcOueELarn8wZoadBVREb9e8ZXO618qfwBxlz1EgHD8Sj6ZEtI9eINtiWkxfENrYk0p25oqQXkxjwEMtK0T1Vha00g1KttzNF";
const stripeTestPromise = loadStripe(PUBLIC_KEY);

const StripeWrapper = (props) => (
  <Elements stripe={stripeTestPromise} options={{ locale: "en" }}>
    <StripeContainer {...props} />
  </Elements>
);

function StripeContainer({
  price,
  rules,
  personalInformationValidationErrors,
  phoneError,
  emailError,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [enablePayButton, setEnablePayButton] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [checkOutError, setCheckOutError] = useState();
  const [state, setState] = useAppState();
  const [t, i18n] = useTranslation("global");
  const [pmId, setPmId] = useState();

  const [serverError, setServerError] = useState(false);
  const [success, setSuccess] = useState(false);

  const [sendingRequest, setSendingRequest] = useState(false);

  //const frontendUrl = "http://localhost:3000/success";

  const frontendUrl = "https://vegtax.ch/success";

  const navigate = useNavigate();
  const location = useLocation();

  const stripeUrl = `${url}stripe-payment`;

  const onCardElementChange = (e) => {
    if (e.empty === false && e.complete === true) {
      setEnablePayButton(true);
    } else {
      setEnablePayButton(false);
    }
  };

  const onSuccessfullPayment = () => {
    setCheckOutError(null);
    //setProcessing(false);
  };

  useEffect(() => {
    if (pmId) {
      submitForm(pmId);
    }
  }, [pmId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const billingInformation = {
      name: state.personalInformation["name and lastname"],
      email: state.personalInformation.email,
      phone: state.personalInformation.mobile,
    };

    setProcessing(true);

    try {
      const res = await axios.post(stripeUrl, {
        data: { amount: price * 100 },
        headers: {
          "Content-Type": "application/json",
        },
      });

      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: billingInformation,
      });

      if (paymentMethodReq.error) {
        setCheckOutError(paymentMethodReq.error.message);
        setProcessing(false);
        return;
      }

      const { error, ...rest } = await stripe.confirmCardPayment(
        res.data.clientSecret,
        {
          payment_method: paymentMethodReq.paymentMethod.id,
          return_url: frontendUrl,
        }
      );

      setPmId(rest?.paymentIntent?.id);

      if (error) {
        setCheckOutError(error.message);
        setProcessing(false);
        return;
      }

      onSuccessfullPayment();
    } catch (e) {
      setCheckOutError(e.message);
      setProcessing(false);
    }
  };

  const submitForm = (id) => {
    const d = new Date();
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    let month = months[d.getMonth()];

    setSendingRequest(true);
    axios
      .post(
        `${url}send-form`,
        {
          form: state,
          paymentDetails: {
            id: id,
            amount: price,
            method: "Card",
            language: i18n.language,
            date: `${d.getDate()} ${month} ${d.getFullYear()}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setServerError(false);
        setSendingRequest(false);
        navigate("/success", { state: { pathname: location.pathname } });
        setSuccess(true);
      })
      .catch((error) => {
        setSuccess(false);
        navigate("/form-sending-error", {
          state: { pathname: location.pathname },
        });
        setServerError(error);
        setSendingRequest(false);
      });
  };

  const cardElementOptions = { hidePostalCode: true };

  return (
    <>
      <div style={{ marginTop: "30px" }}>
        <div
          style={{
            border: "2px solid black",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <CardElement
            options={cardElementOptions}
            onChange={onCardElementChange}
          />
        </div>
        {checkOutError && <div className="payment-error">{checkOutError}</div>}

        <div style={{ marginTop: "30px" }}>
          <button
            className={
              !enablePayButton ||
              !rules ||
              personalInformationValidationErrors === true ||
              emailError === false ||
              phoneError === false
                ? "next-step-button payment-button-disabled"
                : "next-step-button"
            }
            onClick={handleSubmit}
            disabled={
              !enablePayButton ||
              !rules ||
              personalInformationValidationErrors === true ||
              emailError === false ||
              phoneError === false
            }
          >
            {t("finish")}
          </button>
        </div>
      </div>
      {(processing || sendingRequest) && <div className="page-overlay"></div>}
    </>
  );
}

export default StripeWrapper;
