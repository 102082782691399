import React, { useTransition } from "react";
import "./validationerror.css";
import { useTranslation } from "react-i18next";

function ValidationError({ error, topPosition, message }) {
  const [t] = useTranslation("global");
  return (
    <div
      className="validation-error"
      style={{ top: topPosition ? topPosition : "100%" }}
    >
      {error && <p>{message ? message : t("required")}</p>}
    </div>
  );
}

export default ValidationError;
