import React, { useEffect, useRef } from "react";
import "./customselect.css";
import { useTranslation } from "react-i18next";

function CustomSelect({
  onChange,
  value,
  showOptions,
  setShowOptions,
  label,
  selectedValueWidth,
  options,
  handleResetValue,
  optionsHeight,
  overflowOptions,
  partOfList,
  transportId,
}) {
  const newRef = useRef();

  const handleOutsideClick1 = (e) => {
    if (newRef?.current && !newRef?.current?.contains(e.target)) {
      setShowOptions(false);
    }
  };

  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick1);
    //document.addEventListener("mousedown", handleOutsideClick2);
    //document.addEventListener("mouseup", handleOutsideClick2);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick1);
      //document.removeEventListener("mousedown", handleOutsideClick2);
      // document.addEventListener("mouseup", handleOutsideClick2);
    };
  }, []);

  // useEffect(() => {
  //   const handler = (e) => {
  //     if (newRef2.current && !newRef2.current.contains(e.target)) {
  //       setShowOptions(null);
  //     }
  //   };

  //   window.addEventListener("click", handler);
  //   return () => {
  //     window.removeEventListener("click", handler);
  //   };
  // });
  return (
    <>
      {!partOfList && (
        <div
          className="select-container"
          style={{ width: `${selectedValueWidth}` }}
          ref={newRef}
        >
          {!partOfList && (
            <div
              className={
                showOptions ? "selected-value focused" : "selected-value"
              }
              onClick={(e) => {
                if (e.target.id !== "deleteicon") {
                  setShowOptions((prevState) => !prevState);
                }
              }}
            >
              <div className="select-label">{label}</div>
              <span
                style={{
                  position: "absolute",
                  left: "20px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  textTransform: "capitalize",
                }}
              >
                {value}
              </span>
              {/* {showOptions === true && value && (
          <button className="delete-btn" onClick={handleResetValue}>
            <img
              src="deleteIcon.png"
              alt="delete icon"
              id="deleteicon"
              type="button"
            />
          </button>
        )} */}
              {!showOptions && (
                <button className="delete-btn" type="button">
                  <img src=" dropdown-arrow-triangle.png" alt="dropdown icon" />
                </button>
              )}
            </div>
          )}
          {showOptions && (
            <div
              className="options-container"
              style={{
                height: optionsHeight ? optionsHeight : "auto",
                overflowY: overflowOptions ? overflowOptions : "auto",
              }}
            >
              <ul>
                {options.map((item) => {
                  return (
                    <li key={item.id} onClick={() => onChange(item)}>
                      {i18n.language === "en" ? item.value : item.frenchValue}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default CustomSelect;
