import React, { useReducer, useRef } from "react";
import "./transportationmean.css";

import NewCustomSelect from "../newCustomSelect";
import { useTranslation } from "react-i18next";
import ValidationError from "../validationError";

function TransportationMean({
  index,
  handleSelectTypeOfTransport,
  showOptions,
  setShowOptions,
  id,
  value,
  onChange,
  mean,
  handleDeleteTransport,
  transportKmValidationErrors,
  transportPartner2KmValidationErrors,
  fromPartner2DateError,
  fromDateError,
  toDateError,
  toPartner2DateError,
}) {
  const typeOfTransportOptions = [
    { id: 1, value: "Private", frenchValue: "Privé" },
    { id: 2, value: "Public", frenchValue: "Public" },
  ];

  const [t, i18n] = useTranslation("global");

  return (
    <div className="transportation-mean-wrapper">
      <div className="transport-mean-header-wrapper">
        <h6 className="input-title">Transport {index + 1}</h6>
        <button
          className="delete-dependent-child-button"
          onClick={() => handleDeleteTransport(id)}
        >
          <img src="/deleteCitcleButton.png" alt="delete dependent child" />
        </button>
      </div>

      <NewCustomSelect
        options={typeOfTransportOptions}
        onChange={handleSelectTypeOfTransport}
        transportId={id}
        label={t("trefundstep3.fields.type")}
        mean={mean}
      />
      <div className="alimony-input-wrapper" style={{ marginTop: "20px" }}>
        <input
          className="activity-input"
          type="text"
          placeholder={t("trefundstep2.fields.res")}
          onChange={(e) => onChange(e, "domicile", id)}
          value={mean?.["domicile"]}
        />
      </div>
      <div className="alimony-input-wrapper ">
        <input
          className="activity-input"
          type="text"
          placeholder={t("trefundstep3.fields.wplace")}
          onChange={(e) => onChange(e, "lieu de travail", id)}
          value={mean?.["lieu de travail"] || ""}
        />
      </div>

      <div className="alimony-input-wrapper" style={{ position: "relative" }}>
        <ValidationError
          error={transportKmValidationErrors?.[mean.id]}
          message="Numbers only"
          topPosition={"-14px"}
        />
        <ValidationError
          error={transportPartner2KmValidationErrors?.[mean.id]}
          message="Numbers only"
          topPosition={"-14px"}
        />
        <input
          className="activity-input"
          type="text"
          placeholder={t("km")}
          onChange={(e) => onChange(e, "km par jour", id)}
          value={mean?.["km par jour"] || ""}
        />
      </div>
      <div className="alimony-input-wrapper " style={{ position: "relative" }}>
        <ValidationError
          error={fromPartner2DateError?.[mean.id]}
          topPosition={"-12px"}
          message="Valida dates only. DD/MM/YYYY"
        />
        <ValidationError
          error={fromDateError?.[mean.id]}
          topPosition={"-12px"}
          message="Valida dates only. DD/MM/YYYY"
        />
        <input
          className="activity-input"
          type="text"
          placeholder={`${t("trefundstep3.fields.from")} ( DD/MM/YYYY )`}
          onChange={(e) => onChange(e, "du", id)}
          value={mean?.["du"] || ""}
        />
      </div>
      <div className="alimony-input-wrapper " style={{ position: "relative" }}>
        <ValidationError
          error={toPartner2DateError?.[mean.id]}
          topPosition={"-12px"}
          message="Valida dates only. DD/MM/YYYY"
        />
        <ValidationError
          error={toDateError?.[mean.id]}
          topPosition={"-12px"}
          message="Valida dates only. DD/MM/YYYY"
        />
        <input
          className="activity-input"
          type="text"
          placeholder={`${t("trefundstep3.fields.to")} ( DD/MM/YYYY )`}
          onChange={(e) => onChange(e, "au", id)}
          value={mean?.["au"] || ""}
        />
      </div>
    </div>
  );
}

export default TransportationMean;
