import React from "react";
import Success from "../../components/success";
import HowItWorks from "../../components/howItWorks";

function HowItWorksPage(props) {
  return (
    <div className="page-container">
      <HowItWorks />
    </div>
  );
}

export default HowItWorksPage;
