import React from "react";
import Failure from "../../components/failure";

function FailurePage(props) {
  return (
    <div className="page-container">
      <Failure />
    </div>
  );
}

export default FailurePage;
