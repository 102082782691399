import React from "react";
import TermsOfUse from "../../components/termsOfUse";

function TermsOfUsePage(props) {
  return (
    <div className="page-container">
      <TermsOfUse />
    </div>
  );
}

export default TermsOfUsePage;
