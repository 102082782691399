import React, { useState } from "react";
import "./header.css";
import NavBar from "../NavBar";
import { useNavigate } from "react-router";
import LanguageSwitcher from "../languageSwitcher";

function Header(props) {
  const [navigationOpen, setNavigationOpen] = useState(false);

  const navigate = useNavigate();
  return (
    <div className="header">
      <div className="logo-navbar-wrapper">
        <div className="logo" onClick={() => navigate("/")}>
          <img src="/vegtaxLogo.png" alt="vegtax-logo" />
        </div>
        <NavBar
          navigationOpen={navigationOpen}
          setNavigationOpen={setNavigationOpen}
        />
      </div>

      <div className="header-functions-block">
        <div className="language-switcher">
          <LanguageSwitcher />
        </div>
        <div
          className="hamburger"
          onClick={() => setNavigationOpen((prevState) => !prevState)}
        >
          <img src="/hamburger.png" alt="hamburger icon" />
        </div>
      </div>
    </div>
  );
}

export default Header;
