import React, { useEffect, useState } from "react";
import "./filesubmitter.css";
import { useAppState } from "../../contexts/formContext";
import { v4 as uuidv4 } from "uuid";
import ValidationError from "../validationError";
import { useTranslation } from "react-i18next";
import { PDFDocument, PDFCompression } from "pdf-lib";

function FileSubmitter({
  fileList,
  setFileList,
  filesValidationErrors,
  invalidateFilesErrors,
}) {
  const [state, setState] = useAppState();
  const [fileSizeError, setFileSizeError] = useState({});
  const [t, i18n] = useTranslation("global");
  const [showImage, setShowImage] = useState();

  const images = [
    { url: "/documents/Feuille de transmission.jpg" },
    { url: "/documents/Frais de garde.jpeg" },
    { url: "/documents/Attestation de frais médicaux.jpg" },
    { url: "/documents/Attestation de dons.png" },
    { url: `/documents/Attestation d'allocations familiales.jpg` },
    { url: `/documents/Prestations de l'assurance chômage.png` },
    { url: `/documents/Attestation de rentes 1er pillier (AVS).png` },
    { url: `/documents/Attestation bancaire.jpg` },
    { url: "/documents/Attestation de valeur 3b.jpeg" },
    { url: "/documents/Certificat de salaire.jpg" },
    { url: `/documents/Rachat du 2ème pilier (LPP).jpeg` },
    { url: `/documents/Bail à loyer.jpeg` },
    { url: `/documents/Attestation de primes pour l'autorité fiscale (2).jpg` },
    { url: `/documents/Attestation de rentes 2ème pillier (LPP).jpeg` },
    { url: `/documents/Attestation de rentes 3ème pilier (3b).jpg` },
    { url: "/documents/Relevé de compte.jpg" },
    { url: `/documents/Attestation de valeur 3a.png` },
    { url: `/documents/Justificatif de pension alimentaire.jpeg` },
  ];

  const showImages = (e, id, url) => {
    if (id !== "Copie de la décision de taxation 2022") {
      if (e.type === "touchstart") {
        setShowImage({ id, isMobile: true });
      }
      if (e.type === "mouseover") {
        setShowImage({ id, isMobile: false, url });
      }
    }
  };

  const hideImages = (e) => {
    if (e.type === "touchend") {
      setShowImage(null);
    }
    if (e.type === "mouseleave") {
      setShowImage(null);
    }
  };

  async function compressPdf(base64String) {
    const uint8Array = Uint8Array.from(atob(base64String), (c) =>
      c.charCodeAt(0)
    );
    const pdfDoc = await PDFDocument.load(uint8Array);

    // Compress using Flate compression
    pdfDoc.compress([PDFCompression.Flate]);

    const compressedPdfBytes = await pdfDoc.save();

    // Convert compressed PDF bytes back to Base64
    const compressedBase64String = btoa(
      String.fromCharCode.apply(null, compressedPdfBytes)
    );

    return compressedBase64String;
  }

  const fileSelectedHandler = (key, e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    if (file && file?.size > 7000000) {
      setFileSizeError((errors) => {
        return { ...errors, [key]: key };
      });
    } else {
      setFileSizeError((errors) => {
        const { [key]: er, ...rest } = errors;
        return { ...rest };
      });
      reader.onload = (event) => {
        if (event.target.result) {
          // compressPdf(event.target.result.split(",")[1]).then(
          //   (compressedBase64String) => {
          //     // compressedBase64String is the compressed Base64 encoded representation of your PDF
          //     console.log(compressedBase64String);
          //     // Now you can send this compressedBase64String to your backend
          //   }
          // );
          invalidateFilesErrors(key);
          setState((state) => {
            if (state?.files?.[key]) {
              return {
                ...state,
                files: {
                  ...state.files,
                  [key]: [
                    ...state.files[key],
                    {
                      id: uuidv4(),
                      base64: event.target.result,
                      fileName: file.name,
                      fileSize: file.size,
                      type: file.type,
                    },
                  ],
                },
              };
            } else {
              return {
                ...state,
                files: {
                  ...state.files,
                  [key]: [
                    {
                      id: uuidv4(),
                      base64: event.target.result,
                      fileName: file.name,
                      fileSize: file.size,
                      type: file.type,
                    },
                  ],
                },
              };
            }
          });
        }
      };
    }

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const deleteFile = (key, id) => {
    setState((state) => {
      if (state?.files?.[key]?.length === 1) {
        const { [key]: fil, ...rest } = state.files;
        return {
          ...state,
          files: { ...rest },
        };
      } else {
        const newFiles = state.files[key].filter((file) => {
          return file.id !== id;
        });

        return {
          ...state,
          files: { ...state.files, [key]: newFiles },
        };
      }
    });
  };

  const generateFileList = (form) => {
    const files = [];

    files.push({
      fileName: "Copie de la décision de taxation 2022",
      id: "Copiedeladécisiondetaxation2022",
    });

    files.push({
      fileName: "Relevé de compte",
      url: "/documents/Relevé de compte.jpg",
    });

    files.push({
      fileName:
        "Formulaire original du Service cantonal des contributions – Feuille de transmission",
      url: "/documents/Feuille de transmission.jpg",
    });

    if (form["Sélections des revenus"]?.["Activites salariale"]) {
      files.push({
        fileName: "Tous les certificats/attestations de salaires 2023",
        url: "/documents/Certificat de salaire.jpg",
      });
    }

    if (
      form["Allocations familiales type"]?.type ===
      "Versees directement par la CAF"
    ) {
      files.push({
        fileName: "Attestation d’allocations familiales",
        url: `/documents/Attestation d'allocations familiales.jpg`,
      });
    }

    if (form["Sélections des revenus"]?.["Indémnités"]) {
      files.push({
        fileName:
          "Indemnités diverses, attestations du chômage, perte de gain maladie, accident, service militaire",
        url: `/documents/Prestations de l'assurance chômage.png`,
      });
    }

    if (
      form["Rentes et pensions type"]?.find((el) => el.value === "1er pillier")
    ) {
      files.push({
        fileName: "Attestation de la rente AVS/AI",
        url: `/documents/Attestation de rentes 1er pillier (AVS).png`,
      });
    }

    if (
      form["Rentes et pensions type"]?.find((el) => el.value === "2eme pillier")
    ) {
      files.push({
        fileName: "Attestation pour la rente 2ème pilier",
        url: `/documents/Attestation de rentes 2ème pillier (LPP).jpeg`,
      });
    }

    if (
      form["Rentes et pensions type"]?.find((el) => el.value === "3eme pillier")
    ) {
      files.push({
        fileName: "Attestation pour la rente 3ème pilier",
        url: `/documents/Attestation de rentes 3ème pilier (3b).jpg`,
      });
    }

    if (form["Sélections des fortune"]?.["Assurance 3B"]) {
      files.push({
        fileName:
          "Attestation(s) assurance-vie pilier 3 B (attestation de la valeur de rachat au 31.12.2023)",
        url: "/documents/Attestation de valeur 3b.jpeg",
      });
    }

    if (form["Sélections des fortune"]?.["Comptes Bancaires"]) {
      files.push({
        fileName: "Attestations bancaires au 31.12.2023",
        url: `/documents/Attestation bancaire.jpg`,
      });
    }

    if (form["Sélections de déductions"]?.["Cotisatoin OPP3 (3ème pilier A)"]) {
      files.push({
        fileName: "Attestation(s) de versement(s) 3ème pilier 2023",
        url: `/documents/Attestation de valeur 3a.png`,
      });
    }

    if (form["Sélections de déductions"]?.["Rachats dannées dassurances"]) {
      files.push({
        fileName: "Attestation(s) rachat LPP en 2023",
        url: `/documents/Rachat du 2ème pilier (LPP).jpeg`,
      });
    }

    if (form["Sélections de déductions"]?.["Pensions alimentaires"]) {
      files.push({
        fileName: "Justificatif de pension alimentaire",
        url: `/documents/Justificatif de pension alimentaire.jpeg`,
      });
    }

    if (form["Sélections de déductions"]?.["Frais médicaux et dentaires"]) {
      files.push({
        fileName:
          "Factures de dentiste, lunettes, frais médicaux payés personnellement",
        url: "/documents/Attestation de frais médicaux.jpg",
      });
    }

    if (form["Sélections de déductions"]?.["Dons"]) {
      files.push({
        fileName: "Quittances des dons",
        url: "/documents/Attestation de dons.png",
      });
    }

    if (form["Sélections de déductions"]?.["Assurances maladie et accidents"]) {
      files.push({
        fileName: "Primes d’assurance-maladie",
        url: `/documents/Attestation de primes pour l'autorité fiscale (2).jpg`,
      });
    }

    if (form["Sélections de déductions"]?.["Déductions logement"]) {
      files.push({
        fileName: "Bail à loyer",
        url: `/documents/Bail à loyer.jpeg`,
      });
    }

    if (form["Sélections de déductions"]?.["Frais de garde"]) {
      files.push({
        fileName: "Frais de garde des enfants",
        url: "/documents/Frais de garde.jpeg",
      });
    }

    setFileList(files);
    return files;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const fList = generateFileList(state);

    if (state?.files) {
      let newFiles = {};
      Object.keys(state.files).map((key) => {
        const match = fList.find((element) => element.fileName === key);
        if (match) {
          newFiles = { ...newFiles, [key]: state.files[key] };
        }
      });

      setState((state) => {
        return { ...state, files: newFiles };
      });
    }
  }, []);

  return (
    <div>
      {images.map((image) => {
        return (
          <div
            id={image.url}
            className={
              image?.url === showImage?.url
                ? "document-image visible"
                : "document-image"
            }
          >
            <button className="close-image" onClick={() => setShowImage(null)}>
              X
            </button>
            <img src={image.url} />
          </div>
        );
      })}
      <div className="refund-desc">
        <h4> {t("filesubmit.title")}</h4>
      </div>

      {fileList?.length > 0 &&
        fileList.map((item) => {
          return (
            <div
              className="file-input-wrapper"
              key={item.fileName}
              style={{ position: "relative" }}
            >
              <ValidationError
                error={filesValidationErrors?.[item.fileName]}
                message="Required"
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span className="file-input-label">
                  {/* <span className="file-dot"></span> */}
                  {item.fileName}
                </span>
                <div className="file-buttons-wrapper">
                  <div
                    id={item?.id}
                    className="info-icon"
                    onMouseOver={(e) =>
                      showImages(e, item.fileName, item.url, item.id)
                    }
                    onMouseLeave={(e) => hideImages(e)}
                    onTouchStart={(e) =>
                      showImages(e, item.fileName, item.url, item.id)
                    }
                    onTouchEnd={(e) => hideImages(e)}
                  >
                    <img src="/infoIcon.png" alt="" />
                    {item?.id === "Copiedeladécisiondetaxation2022" && (
                      <p className="show-info" id="filepopup">
                        Si votre décision de taxation 2022 n'a pas encore été
                        produite par l'autorité fiscale, veuillez nous remettre
                        copie de votre déclaration d'impôt 2022 déposée
                      </p>
                    )}
                  </div>

                  <label htmlFor={item.fileName} className="file-input-button">
                    <img src="fileUploadIcon.png" alt="file upload" />
                  </label>
                </div>
              </div>

              {state?.files?.[item.fileName]?.length > 0 && (
                <ul>
                  {state?.files?.[item.fileName].map((file) => {
                    return (
                      <li className="filename" key={file.id}>
                        <span className="filename-text">{file.fileName}</span>

                        <button
                          className="delete-file-button"
                          onClick={() => deleteFile(item.fileName, file.id)}
                        >
                          <img src="deleteCitcleButton.png" />
                        </button>
                      </li>
                    );
                  })}
                </ul>
              )}
              {fileSizeError?.[item.fileName] && (
                <ValidationError
                  error={fileSizeError?.[item.fileName]}
                  message="Only files les than 7MB"
                  topPosition={
                    state?.files?.[item.fileName]?.length > 0 ? "100%" : "124%"
                  }
                />
              )}

              <input
                id={item.fileName}
                type="file"
                accept="image/png, image/jpeg, application/pdf"
                onChange={(e) => fileSelectedHandler(item.fileName, e)}
              />
            </div>
          );
        })}
    </div>
  );
}

export default FileSubmitter;
