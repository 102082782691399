import React from "react";
import Success from "../../components/success";

function SuccessPage() {
  return (
    <div className="page-container">
      <Success />
    </div>
  );
}

export default SuccessPage;
